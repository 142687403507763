/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_400_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_500_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_600_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_700_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_800_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.logo img {
  width: 25%;
  padding-top: 32px;
  padding-bottom: 32px;
}

.navbar-brand img {
  width: 18px;
}

.report-wrapper {
  --bg-color: var(--blockbw);
}

.standard-actions {
  --control-bg: var(--blockbw);
}

.page-actions {
  --control-bg: var(--blockbw);
}

.sidebar-menu {
  --control-bg: var(--blockbw);
}

.form-footer {
  --control-bg: var(--blockbw);
}

.widget.onboarding-widget-box {
  --bg-color: var(--blockbw);
}

.list-sidebar .list-filters input:not([data-fieldtype=Check]) {
  background: var(--blockbw);
}

.col.layout-main-section-wrapper {
  scrollbar-color: var(--blockbw);
}

[data-page-route=Workspaces] .layout-main .layout-main-section-wrapper {
  scrollbar-color: var(--scrollbar-thumb-color);
}

[data-page-route=Workspaces] .layout-main .layout-side-section {
  scrollbar-color: var(--scrollbar-thumb-color);
}

:root {
  --brand-color: #6B397D;
  --primary: #6B397D;
  --primary-color: #6B397D;
  --secondary: #2F98D5;
  --secondary-color: #2F98D5;
  --bg-color: #eef7fc;
  --blockbw: #ffffff;
  --sidebar-select-color: #ffffff;
  --scrollbar-track-color: #ffffff;
  --scrollbar-thumb-color: #2F98D5;
  --avatar-frame-bg: #ffffff;
  --awesomplete-hover-bg: #ffffff;
  --subtle-fg: #ffffff;
  --font-stack: "Raleway", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
}

[data-theme=dark]:root {
  --brand-color: #6B397D;
  --primary: #6B397D;
  --primary-color: #6B397D;
  --secondary: #2F98D5;
  --secondary-color: #2F98D5;
  --bg-color: #381e41;
  --blockbw: #000000;
  --sidebar-select-color: #000000;
  --scrollbar-track-color: #000000;
  --scrollbar-thumb-color: #2F98D5;
  --avatar-frame-bg: #000000;
  --awesomplete-hover-bg: #000000;
  --subtle-fg: #000000;
  --font-stack: "Raleway", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
}